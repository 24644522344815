// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourstory-js": () => import("./../../../src/pages/ourstory.js" /* webpackChunkName: "component---src-pages-ourstory-js" */),
  "component---src-pages-registry-js": () => import("./../../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-subpages-contactform-js": () => import("./../../../src/pages/subpages/contactform.js" /* webpackChunkName: "component---src-pages-subpages-contactform-js" */),
  "component---src-pages-subpages-home-jsx": () => import("./../../../src/pages/subpages/Home.jsx" /* webpackChunkName: "component---src-pages-subpages-home-jsx" */),
  "component---src-pages-subpages-registrypage-js": () => import("./../../../src/pages/subpages/registrypage.js" /* webpackChunkName: "component---src-pages-subpages-registrypage-js" */),
  "component---src-pages-subpages-spage-js": () => import("./../../../src/pages/subpages/spage.js" /* webpackChunkName: "component---src-pages-subpages-spage-js" */),
  "component---src-pages-subpages-storypage-js": () => import("./../../../src/pages/subpages/storypage.js" /* webpackChunkName: "component---src-pages-subpages-storypage-js" */),
  "component---src-pages-subpages-travelaccommodations-js": () => import("./../../../src/pages/subpages/travelaccommodations.js" /* webpackChunkName: "component---src-pages-subpages-travelaccommodations-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

